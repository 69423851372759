import React from 'react';
import './SmallTitle.css';
const SmallTitle = () => {
  return (
    <div className="small-tree-title">
        <div className="small-the">the</div>
        <div className="small-tree-chronicles">TRee ChRoNiCLeS</div>
        {/* <div className= "by-dina"> by Dina</div> */}
    </div>
  )
}

export default SmallTitle;